<template>
  <div class="recycler" ref="recycler" :class="`${myClass}`" :style="style" @click="onVisible" v-bind="bindRecycler({})" @mouseenter="onVisible">
    <iconic v-if="!isVisible && icon" class="recycler-icon" :name="icon" :style="styleIcon" />
    <slot v-if="isVisible" ref="slot"></slot>
  </div>
</template>

<script>
export default {
  props: ["icon", "disabled", "recycle"],
  data: function() {
    return {
      observer: null,
      isVisible: true,
      myClass: "",
      started: true,
      threshold: [0.0, 0.4],
      bufferTime: 500,
      style: {
        "min-height": "auto",
      },
      styleIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "font-size": "2rem",
      },
    };
  },
  computed: {
    offRecycle: function() {
      return this.recycle === false || this.recycle === "false";
    },
  },
  methods: {
    observe: function(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      if (this.started) {
        this.started = false;
        this.onVisible({ target });
      } else if (isVisible) {
        this.onVisible({ target });
      } else {
        this.onHidden({ target });
      }
    },
    onVisible: function(element = {}) {
      if (!element.target) {
        return this.bindRecycler();
      }

      let { target } = element;

      if (target) this.setTargetProps({ target });

      this.isVisible = true;
      this.myClass = "visible";

      this.$emit("visible");
    },
    onHidden: function({ target }) {
      if (target) this.setTargetProps({ target });

      this.myClass = "";

      if (!this.offRecycle) {
        this.isVisible = false;
      }

      this.$emit("invisible");
    },
    setTargetProps: function({ target }) {
      let height = target.offsetHeight;
      let slot = this.$refs.slot;

      if (slot) {
        height = slot.offsetHeight;
      }

      if (!this.disabled) {
        this.style["min-height"] = height + "px";
      }
    },
    recycler: function() {
      this.observer = new IntersectionObserver(this.observe, {
        threshold: this.threshold,
      });
      this.observer.observe(this.$refs.recycler);
      this.$emit("recycler");
    },
    bindRecycler: async function() {
      await this.sleep(this.bufferTime);

      const element = this.$refs.recycler;

      if (!element) {
        return;
      }

      const onViewport = this.elementInViewport(element);

      if (onViewport) {
        this.onVisible(this.$refs.recycler);
      }
    },
    elementInViewport: function(el) {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
      );
    },
    sleep: function(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    startRecycler: async function() {
      if (!this.$refs.recycler) {
        await this.sleep(100);
        return this.startRecycler();
      }

      this.recycler();
      return;
    },
  },
  mounted: function() {
    if (!this.disabled) {
      this.started = true;
      this.startRecycler();
    }
  },
  destroyed: function() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>
